import React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'


const IndexPage= () => {
    return(
        <Layout>
        <Head title="Home"/>
       <h1>Hello<span role="img" aria-label="hello">👋</span></h1>
        <h3>I am Imtiaz!</h3><h4>developer, based in Toronto, Canada</h4>
        <p>Check out my latest <Link to="/blog">blog</Link> post </p>
       </Layout>
       
    )
}
 export default IndexPage